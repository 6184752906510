import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TranslationService } from "src/assets/i18n";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ServicesService {
    servicesData = new BehaviorSubject(null);
    servicesDataById = new BehaviorSubject(null);
    servicesDetailsById = new BehaviorSubject(null);
    contact_section = new BehaviorSubject(null);
    mainServicesData = new BehaviorSubject(null);

    portfolioForSubService = new BehaviorSubject(null);
    portfolioForMainService = new BehaviorSubject(null);

    projectDataById = new BehaviorSubject(null);
    portfolioItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    data = new BehaviorSubject(null);

    constructor(
        private httpClient: HttpClient,
        private translationService: TranslationService
    ) {}

    getUrl() {
        const currentLang = this.translationService.getSelectedLanguage();
        return currentLang === "en"
            ? "assets/models/services.json"
            : "assets/models/servicesRTL.json";
    }

    getServices() {
        this.httpClient.get(this.getUrl()).subscribe({
            next: (result: any) => {
                this.servicesData.next(result.data);
                this.contact_section.next(result.contact_section);
            },
        });
    }

    getGeneralData() {
        this.httpClient.get(this.getUrl()).subscribe({
            next: (result: any) => {
                this.data.next(result);
            },
        });
    }

    getServicesById(id: any, serviceId?: any) {
        this.httpClient.get(this.getUrl()).subscribe({
            next: (result: any) => {
                // Iterate over the main services array
                result.data.mainServices.forEach((item: any) => {
                    if (item.id == id) {
                        this.servicesDataById.next(item.servicesDetails);
                        this.mainServicesData.next(item);
                        // Check if we need to find a specific service
                        if (serviceId) {
                            item.servicesDetails.services.forEach(
                                (service: any) => {
                                    if (service.id == serviceId) {
                                        this.servicesDetailsById.next(service);
                                    }
                                }
                            );
                        }
                    }
                });
            },
        });
    }

    getPortfolioBySubService(mainServiceId: any, subServiceUniqueId?: any) {
        this.httpClient.get(this.getUrl()).subscribe({
            next: (result: any) => {
                let mainServiceData: any[] = [];
                let subServiceData: any[] = [];
                let mainServiceDetails: any[] = [];
                let subServiceDetails: any[] = [];

                // Process the result to get the mainServiceData and subServiceData
                result.all_protfolio_services.data.forEach((item: any) => {
                    if (item.mainServiceId === mainServiceId) {
                        mainServiceData = item.subServices.flatMap(
                            (service: any) => service.protfolio_data
                        );

                        if (subServiceUniqueId) {
                            const subService = item.subServices.find(
                                (service: any) =>
                                    service.subService_Id === subServiceUniqueId
                            );
                            if (subService) {
                                subServiceData = subService.protfolio_data;

                                subServiceData.forEach((item: any) => {
                                    result.project.projects.forEach(
                                        (project: any) => {
                                            if (item.id === project.id) {
                                                subServiceDetails.push(project);
                                            }
                                        }
                                    );
                                });

                                // Remove duplicates from subServiceDetails
                                subServiceDetails =
                                    this.removeDuplicates(subServiceDetails);
                            }
                        }
                    }
                });

                // Remove duplicates from mainServiceData
                mainServiceData = this.removeDuplicates(mainServiceData);

                // Populate mainServiceDetails with projects corresponding to mainServiceData
                mainServiceData.forEach((item: any) => {
                    result.project.projects.forEach((project: any) => {
                        if (item.id === project.id) {
                            mainServiceDetails.push(project);
                        }
                    });
                });

                // Remove duplicates from mainServiceDetails
                mainServiceDetails = this.removeDuplicates(mainServiceDetails);

                // Update observables with the results
                this.portfolioForMainService.next(mainServiceDetails);
                if (subServiceUniqueId) {
                    this.portfolioForSubService.next(subServiceDetails);
                }
            },
        });
    }

    getThreeRandomPortfolioItems() {
        let portfolioArray: any[] = [];

        this.httpClient.get<any>(this.getUrl()).subscribe({
            next: (result) => {
                result.project.projects.forEach((project) => {
                    portfolioArray.push(...[project]);
                });

                // Get three random portfolio items
                const randomItems = this.getRandomItems(portfolioArray, 3);
                this.portfolioItems.next(randomItems);
                console.log(this.portfolioItems);
            },
        });
    }

    getRandomItems(array: any[], count: number): any[] {
        let shuffled = array.slice(0),
            i = array.length,
            min = i - count,
            temp,
            index;

        while (i-- > min) {
            index = Math.floor((i + 1) * Math.random());
            temp = shuffled[index];
            shuffled[index] = shuffled[i];
            shuffled[i] = temp;
        }

        return shuffled.slice(min);
    }

    removeDuplicates(items: any[]) {
        return items.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
        );
    }

    getProjectsById(id: any) {
        this.httpClient.get(this.getUrl()).subscribe({
            next: (result: any) => {
                result.project.projects.forEach((item: any) => {
                    if (item.id == id) {
                        this.projectDataById.next(item);
                    }
                });
            },
        });
    }
}
