// USA
export const locale = {
    lang: "en",
    dir: "ltr",
    data: {
        GENERAL: {
            NAME: "Your Name",
            EMAIL: " Your Email",
            PHONE: " Phone",
            MESSAGE: " Message",
            SUBJECT: " Subject",
            SERICE_TYPE: "Service Type",
            REQUIEST_TYPE: "Request Type",
            MSG_ERROR: "Please Complete Data",
            MSG_SUCCESS: "Your Message has been sent successfully",
            UPLOAD: "Upload File",
            REQUEST_SERVICE: "Request A Service",
            COMPLAINT: "Complaint",
            INQUERY: "Inquery",
            TITLE_FORM: "Service Request",
            BTN_FORM: "Send Message",
            MAIN_GOALS: "Main Goals",
            APP_COMPONENTS: "App Components",
            TECHNIQES_FEATURES: "Technical Features",
            TECHNOLOGY_USED: "Technology Used",
            USER_INTERFACES: "User Interfaces",
            SUPERVISION_AND_QUALITY: "Supervision and Quality",
            LANG: " العربية",
            FOLLOW_US: "Follow Us",
            LOADING: "Please wait...",
            SUBJECT_REQUIRED: "Subject is required",
            SUBJECT_MIN: "Subject must be at least 3 characters",
            MSG_REQUIRED: "Message is required",
            MSG_MIN: "Message must be at least 3 characters",
            NAME_REQUIRED: "Name is required",
            NAME_MIN: "Name must be at least 3 characters",
            EMAIL_REQUIRED: "Email is required",
            EMAIL_INVALID: "Email is not valid",
            EMAIL_LENGTH: "Email must be at least 3 characters",
            PHONE_REQUIRED: "Phone is required",
            TYPE_REQUEST_REQUIRED: "Request type is required",
            TYPE_SERVICE_REQUIRED: "Service type is required",
        },
    },
};
