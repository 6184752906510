<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + projectData?.image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ projectData?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                    <div class="header-btn">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="projectData?.btn.btn_icon"></i><span></span> {{ projectData?.btn.btn_name
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <!-- <div
                    class="app-download-image wow animate__animated animate__fadeInLeft"
                    data-wow-delay=".3s"
                >
                    <img src="assets/img/mobile.png" alt="image" />
                </div> -->
                <div class="wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <img [src]="projectDetails?.image" alt="image" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <h4>{{ projectDetails?.title }}</h4>
                    <p>
                        {{ projectDetails?.description }}
                    </p>
                    <span class="sub-title mt-3"
                        *ngIf="projectDetails?.buttons[0].url || projectDetails?.buttons[1].url">{{
                        projectDetails?.sub_title }}</span>
                    <div class="btn-box">
                        <div *ngFor="let item of projectDetails?.buttons">
                            <a class="apple-store-btn mb-3" [href]="item?.url" *ngIf="item?.url">
                                <img [src]="item?.image_src" />
                                {{ item?.text }}
                                <span>{{ item?.store }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="projectDetails?-details-content mt-5">
            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.main_goals">
                <h3 translate="GENERAL.MAIN_GOALS"></h3>
                <p *ngFor="let goal of projectDetails?.detailed_description?.main_goals">
                    {{ goal }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.components">
                <h4 translate="GENERAL.APP_COMPONENTS"></h4>
                <p *ngFor="let component of projectDetails?.detailed_description.components">
                    {{ component }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description.educational_features">
                <h4 translate="GENERAL.TECHNIQES_FEATURES"></h4>
                <p *ngFor="let feature of projectDetails?.detailed_description?.educational_features">
                    {{ feature }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.supervision_and_quality">
                <h4 translate="GENERAL.SUPERVISION_AND_QUALITY"></h4>
                <p> {{ projectDetails?.detailed_description?.supervision_and_quality }} </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.technology_used">
                <h4 translate="GENERAL.TECHNOLOGY_USED"></h4>
                <p *ngFor="let tech of projectDetails?.detailed_description?.technology_used">
                    {{ tech }}
                </p>
            </div>

            <div class="mb-3" *ngIf="projectDetails?.detailed_description?.user_interfaces">
                <h4 translate="GENERAL.USER_INTERFACES"></h4>
                <p *ngFor="let ui of projectDetails?.detailed_description?.user_interfaces">
                    {{ ui }}
                </p>
            </div>

            <p *ngIf="projectDetails?.detailed_description?.conclusion">{{
                projectDetails?.detailed_description?.conclusion }}</p>

            <!-- <div class="service-details-info">
                <div class="single-info-box">
                    <h4>Client</h4>
                    <span>James Anderson</span>
                </div>
                <div class="single-info-box">
                    <h4>Category</h4>
                    <span>Saas, Marketing</span>
                </div>
                <div class="single-info-box">
                    <h4>Date</h4>
                    <span>February 28, 2023</span>
                </div>
                <div class="single-info-box">
                    <a class="default-btn rounded-pill" href="#">Live Preview</a>
                </div>
            </div> -->
        </div>
    </div>
</div>



<!-- <div class="services-area ptb-100 bg-f4f6fc">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/home-saas/feature5.png" alt="image" />
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="fun-facts-inner-content">
                        <h2>Build Beautiful Interface Into Your Application</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut
                            aliquip.
                        </p>
                        <div class="app-download-content">
                            <span class="sub-title">Download App</span>
                            <div class="btn-box mt-0">
                                <a href="#" class="apple-store-btn"
                                    ><img
                                        src="assets/img/applestore.png"
                                        alt="image"
                                    />Download on the<span>Apple Store</span></a
                                >
                                <a href="#" class="play-store-btn"
                                    ><img
                                        src="assets/img/playstore.png"
                                        alt="image"
                                    />Get it on<span>Google Play</span></a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image" />
    </div>
</div> -->

<!-- <section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>
            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello&#64;jexsa.com" name="EMAIL" required>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>
            <div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/shape15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/shape16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/shape17.png" alt="image"></div>
        </div>
    </div>
</section> -->

<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>