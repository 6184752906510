import { BrowserModule } from "@angular/platform-browser";
// import { NgModule } from '@angular/core';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/layouts/navbar/navbar.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ChattingComponent } from "./components/common/chatting/chatting.component";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ServiceDetailsComponent } from "./components/pages/service-details/service-details.component";
import { ProtofolioComponent } from "./components/pages/protofolio/protofolio.component";
import { Project1Component } from "./components/pages/project-1/project-1.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import {
    HashLocationStrategy,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        PreloaderComponent,
        HomeOneComponent,
        FooterComponent,
        AboutComponent,
        PricingComponent,
        TeamComponent,
        ContactComponent,
        ErrorComponent,
        ServicesOneComponent,
        ServicesDetailsComponent,
        BlogGridComponent,
        BlogRightSidebarComponent,
        BlogDetailsComponent,
        ChattingComponent,
        ServiceDetailsComponent,
        ProtofolioComponent,
        Project1Component,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        InlineSVGModule,
        TranslateModule.forRoot(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
