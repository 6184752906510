<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(' + servicesDetailsById?.main_image + ')'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700 ">{{ servicesDetailsById?.header }}</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                    <div class="header-btn">
                        <button routerLink="/contact" class="default-btn rounded-pill"><i
                                [class]="servicesDetailsById?.btn.btn_icon"></i><span></span> {{
                            servicesDetailsById?.btn.btn_name
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details ptb-100">
    <div class="container">
        <h3>{{ servicesData?.title }}</h3>
        <ul class="breadcrumb fs-6 mx-2 mt-1 mb-3">
            <li><a [routerLink]="['/service', id]">
                    <p class="fw-normal fs-6">{{ id }}</p>
                </a></li>
            <li>
                <a class="cursor-none">
                    <p class="fw-normal fs-6">{{ serviceId }}</p>
                </a>
            </li>
        </ul>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 p-lg-0">
                <div class="wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <img [src]="servicesDetailsById?.image" alt="image" class="rounded-8" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-lg-0">
                <div class="contact-form rounded-form">
                    <h3 class="mt-3">{{ servicesDetailsById?.title_form }}</h3>
                    <form [formGroup]="contactForm" class="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control rounded-5 mb-2"
                                        required [placeholder]="'GENERAL.NAME' | translate" formControlName="Name" />
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'required',
                                            message: 'GENERAL.NAME_REQUIRED' | translate,
                                            control: contactForm.controls['Name']
                                        }"></ng-container>
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'minlength',
                                            message: 'GENERAL.NAME_MIN' | translate,
                                            control: contactForm.controls['Name']
                                        }"></ng-container>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control rounded-5 mb-2"
                                        required [placeholder]="'GENERAL.EMAIL' | translate" formControlName="Email" />
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'required',
                                            message: 'GENERAL.EMAIL_REQUIRED' | translate,
                                            control: contactForm.controls['Email']
                                        }"></ng-container>
                                    <ng-container *ngIf="!contactForm.controls['Email'].errors?.minlength"
                                        [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'email',
                                            message: 'GENERAL.EMAIL_INVALID' | translate,
                                            control: contactForm.controls['Email']
                                        }"></ng-container>
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'minlength',
                                            message: 'GENERAL.EMAIL_LENGTH' | translate,
                                            control: contactForm.controls['Email']
                                        }"></ng-container>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="number" name="phone_number" id="phone_number" required
                                        class="form-control rounded-5 mb-2" [placeholder]="'GENERAL.PHONE' | translate"
                                        formControlName="PhoneNumber" />

                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'required',
                                            message: 'GENERAL.PHONE_REQUIRED' | translate,
                                            control: contactForm.controls['PhoneNumber']
                                        }"></ng-container>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject"
                                        class="form-control rounded-5 mb-2" required
                                        [placeholder]="'GENERAL.SUBJECT' | translate" formControlName="Subject" />
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'required',
                                        message: 'GENERAL.SUBJECT_REQUIRED' | translate,
                                        control: contactForm.controls['Subject']
                                    }"></ng-container>
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                        validation: 'minlength',
                                        message: 'GENERAL.SUBJECT_MIN' | translate,
                                        control: contactForm.controls['Subject']
                                    }"></ng-container>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control rounded-5 mb-2" id="message" cols="30"
                                        rows="6" required [placeholder]="'GENERAL.MESSAGE' | translate"
                                        formControlName="Message"></textarea>
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'required',
                                            message: 'GENERAL.MSG_REQUIRED' | translate,
                                            control: contactForm.controls['Message']
                                        }"></ng-container>
                                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                            validation: 'minlength',
                                            message: 'GENERAL.MSG_MIN' | translate,
                                            control: contactForm.controls['Message']
                                        }"></ng-container>
                                </div>
                            </div>
                            <ng-container>
                                <div class="col-lg-6 col-md-6 col-12 mb-3">
                                    <label for="fileInput" class="custom-file-upload rounded-pill">
                                        <input type="file" id="fileInput" style="display:none;"
                                            (change)="onFileSelected($event)">
                                        <img src="https://res.cloudinary.com/dtutqsucw/image/upload/v1438960670/file-upload.png"
                                            class="mx-1">
                                        <span class="mx-2" translate="GENERAL.UPLOAD"></span>
                                    </label>
                                </div>
                            </ng-container>
                            <div
                                class="col-lg-6 col-md-6 col-12 d-flex justify-content-lg-end justify-content-center mb-3 align-items-center">
                                <button type="submit" class="default-btn rounded-pill" [disabled]="contactForm.invalid">
                                    <i class="bx bxs-paper-plane" style="color: #fff"></i>
                                    <ng-container *ngIf="!isLoading">
                                        {{ servicesDetailsById?.btn_form }}
                                    </ng-container>
                                    <ng-container *ngIf="isLoading">
                                        <div translate="GENERAL.LOADING" class="d-inline-block mx-2"></div>
                                        <div class="spinner-border spinner-border-sm text-light px-1"></div>
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="service-details-content mt-5">
            <h3 class="my-2"> {{ servicesDetailsById?.title }} </h3>
            <p class="mb-5" style="white-space: pre-line;">
                {{ servicesDetailsById?.subTitle }}
            </p>
            <div *ngFor="let item of servicesDetailsById?.description">
                <h4 class="my-2">{{ item.title }}</h4>
                <p class="lh-lg" style="white-space: pre-line;">
                    {{ item.description }}
                </p>
            </div>
        </div>
    </div>
</div>

<section class="team-area mb-5" *ngIf="protfolioForSubService.length > 0">
    <div class="container">
        <h3 class="mb-4 text-center">{{ servicesDetailsById?.category }}</h3>
        <div class="row">
            <!-- <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of protfolioForSubService">
                <div class="image-2 w-100">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item?.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <div class="overlay">
                        <div class="overlay-content">
                            <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                            <div class="fs-normal" style="font-size: 12px;">{{ item?.description }}</div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of protfolioForSubService">
                <div class="image-2 w-100 mb-5" [routerLink]="['/project', item.id]">
                    <img class="rounded-1 cursor-pointer w-100 object-fit-cover" [src]="item?.image"
                        sizes="(max-width: 396px) 100vw, 396px" [routerLink]="['/project', item.id]">
                    <!-- <div class="overlay"> -->
                    <div class="text-center mt-4">
                        <h6 class="text-dark fw-bold">{{ item?.title }}</h6>
                        <div class="fs-normal px-3 description" style="font-size: 12px; color: #4a6f8a;">{{
                            item?.description }}
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>


<section class="free-trial-area ptb-100">
    <div class="container">
        <div class="free-trial-content">
            <h3 class="fw-semibold">{{ contact_section?.title }}</h3>
            <p class="fw-medium">
                {{ contact_section?.description }}
            </p>
            <button routerLink="/contact" class="default-btn rounded-pill"><i
                    [class]="contact_section?.btn.btn_icon"></i> {{
                contact_section?.btn.btn_name }}<span></span></button>
        </div>
    </div>
</section>




<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
        <div class="fv-plugins-message-container">
            <span role="alert" class="text-danger">{{ message }}</span>
        </div>
    </ng-container>
</ng-template>