export const locale = {
    lang: "ar",
    dir: "rtl",
    data: {
        GENERAL: {
            NAME: "الاسم",
            EMAIL: "البريد الالكتروني",
            PHONE: "رقم الهاتف",
            MESSAGE: "رسالتك",
            SUBJECT: "الموضوع",
            SERICE_TYPE: "نوع الخدمة",
            REQUIEST_TYPE: "نوع الطلب",
            MSG_ERROR: "من فضلك اكمل البيانات",
            MSG_SUCCESS: "تم الارسال بنجاح",
            UPLOAD: " رفع ملف",
            REQUEST_SERVICE: "طلب خدمة",
            COMPLAINT: "شكوي",
            INQUERY: "استفسار",
            TITLE_FORM: "طلب خدمة",
            BTN_FORM: "ارسال",
            MAIN_GOALS: "الأهداف الرئيسية",
            APP_COMPONENTS: "مكونات التطبيق",
            TECHNIQES_FEATURES: "المميزات التقنية",
            TECHNOLOGY_USED: " التقنية المستخدمة",
            USER_INTERFACES: "واجهات المستخدم",
            SUPERVISION_AND_QUALITY: "الإشراف والجودة",
            LANG: "English",
            FOLLOW_US: "تابعنا",
            LOADING: "يرجى الانتظار...",
            SUBJECT_REQUIRED: "الموضوع مطلوب",
            SUBJECT_MIN: "يجب ان يحتوي على الأقل 3 حروف",
            MSG_REQUIRED: "الرسالة مطلوب",
            MSG_MIN: "يجب ان يحتوي على الأقل 3 حروف",
            NAME_REQUIRED: "الاسم مطلوب",
            NAME_MIN: "يجب ان يحتوي على الأقل 3 حروف",
            EMAIL_REQUIRED: "البريد الالكتروني مطلوب",
            EMAIL_INVALID: "البريد الالكتروني غير صالح",
            EMAIL_LENGTH: "البريد الالكتروني يجب ان يكون على الأقل 3 حروف",
            PHONE_REQUIRED: "رقم الهاتف مطلوب",
            TYPE_REQUEST_REQUIRED: "نوع الطلب مطلوب",
            TYPE_SERVICE_REQUIRED: "نوع الخدمة مطلوبة",
        },
    },
};
