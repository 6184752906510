import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ServicesService } from "src/app/services/services.service";

@Component({
    selector: "app-project-1",
    templateUrl: "./project-1.component.html",
    styleUrl: "./project-1.component.scss",
})
export class Project1Component {
    id: any;

    private unsubscribe: Subscription[] = [];
    projectData: any;
    projectDetails: any;
    contact_section: any;

    _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private services: ServicesService,
        private activatedRoute: ActivatedRoute,
        private cdf: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.activatedRoute.paramMap
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((paramMap) => {
                this.id = paramMap.get("id");
                this.getData();
                this.fetchProjectData();
            });
    }

    getData() {
        this.services.getServices();
        this.services.getGeneralData();
        this.services.data
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.projectData = data?.project;
                console.log(this.projectData, "projectData");
                this.cdf.detectChanges();
            });
        this.services.contact_section
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((contact_section) => {
                this.contact_section = contact_section;
                this.cdf.detectChanges();
            });
    }

    fetchProjectData() {
        this.services.getProjectsById(this.id);
        this.services.projectDataById
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((projectDetails) => {
                this.projectDetails = projectDetails;
                console.log(this.projectDetails, "projectDetails");
                this.cdf.detectChanges();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
